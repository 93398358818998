.input-with-error {
    display: inline-flex;
    flex-direction: column;
}

.error-message {
    color: red !important;
    font-size: smaller !important;
}

.error-text:before {
    content: url(/images/error.png);
    padding-right: 5px;
}

.doc-icon {
    float: right;
    position: -webkit-sticky;
    position: sticky;
    right: 5px;
    top: 5px;
    z-index: 1000;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader.content {
    position: relative;
    padding: 20px;
}

.loader.content .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ui.modal>.content {
    background: none !important;
}

.blurring.dimmable>.dimmer {
    background-color: rgba(0,0,0,.3) !important; 
}
@media only screen and (max-width: 767px) {
  .ui.column.grid>[class*="five wide"].column, .ui.grid>.column.row>[class*="five wide"].column, .ui.grid>.row>[class*="five wide"].column, .ui.grid>[class*="five wide"].column {
    /* width: 31.25%!important; */
    /*width: 100%!important;*/
    padding: 2px;
  }
  }


.bar.ui.segment {
  margin: 10px 20px 0px 20px !important;
  box-shadow: none !important;
  box-sizing: border-box;
  padding: 5px !important;
  background: lightgrey
}

.bar > .ui.grid {
  width: 70%;
  display: inline-flex !important;
}

@media only screen and (max-width: 767px) {
.bar > .ui.stackable.grid {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
}
}

.right-align-edit-icon {
  display: inline !important;
}

.ui.stackable.grid > .column.noPadding {
  padding: 0px !important;
}
/* TEMP HTH
.ui.stackable.grid > .column.myPadding {
  padding: 15px 5px 15px 0px !important;
}
*/
.ui.grid > .column.myPadding {
  padding: 15px 5px 15px 0px !important;
}

.App {
  #text-align: center;
}

.App-logo {
  #animation: App-logo-spin 1s linear;
  #height: 40vmin;

  max-width:100%; 
  height:auto; 
  max-height: 30px;
}

.App-header {
  background-color: #282c3466;
  #min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width:auto; 
  text-align:center; 
  padding: 5px 10px 10px 5px;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 99999;
  top: 0;
}

.App-link {
  color: #61dafb;
}

.accordion {
  width : auto !important;
  /*margin: 20px;*/
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

