.input-with-error {
    display: inline-flex;
    flex-direction: column;
}

.error-message {
    color: red !important;
    font-size: smaller !important;
}

.error-text:before {
    content: url(/images/error.png);
    padding-right: 5px;
}

.doc-icon {
    float: right;
    position: -webkit-sticky;
    position: sticky;
    right: 5px;
    top: 5px;
    z-index: 1000;
}