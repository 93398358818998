.loader.content {
    position: relative;
    padding: 20px;
}

.loader.content .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ui.modal>.content {
    background: none !important;
}

.blurring.dimmable>.dimmer {
    background-color: rgba(0,0,0,.3) !important; 
}