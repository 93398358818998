@media only screen and (max-width: 767px) {
  .ui.column.grid>[class*="five wide"].column, .ui.grid>.column.row>[class*="five wide"].column, .ui.grid>.row>[class*="five wide"].column, .ui.grid>[class*="five wide"].column {
    /* width: 31.25%!important; */
    /*width: 100%!important;*/
    padding: 2px;
  }
  }


.bar.ui.segment {
  margin: 10px 20px 0px 20px !important;
  box-shadow: none !important;
  box-sizing: border-box;
  padding: 5px !important;
  background: lightgrey
}

.bar > .ui.grid {
  width: 70%;
  display: inline-flex !important;
}

@media only screen and (max-width: 767px) {
.bar > .ui.stackable.grid {
    width: min-content !important;
}
}

.right-align-edit-icon {
  display: inline !important;
}

.ui.stackable.grid > .column.noPadding {
  padding: 0px !important;
}
/* TEMP HTH
.ui.stackable.grid > .column.myPadding {
  padding: 15px 5px 15px 0px !important;
}
*/
.ui.grid > .column.myPadding {
  padding: 15px 5px 15px 0px !important;
}

.App {
  #text-align: center;
}

.App-logo {
  #animation: App-logo-spin 1s linear;
  #height: 40vmin;

  max-width:100%; 
  height:auto; 
  max-height: 30px;
}

.App-header {
  background-color: #282c3466;
  #min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width:auto; 
  text-align:center; 
  padding: 5px 10px 10px 5px;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 99999;
  top: 0;
}

.App-link {
  color: #61dafb;
}

.accordion {
  width : auto !important;
  /*margin: 20px;*/
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
